import React, { useEffect } from "react";
import "./footer.css";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { checkAndUpdateLanguage } from '../i18n/i18n';

const Footer = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const language = checkAndUpdateLanguage(i18n.language);
        console.log('Footer component rendered. Current language:', language);

        const handleLanguageChange = () => {
            console.log('Language change event detected in Footer');
            checkAndUpdateLanguage(i18n.language);
        };

        window.addEventListener('languageChanged', handleLanguageChange);

        return () => {
            window.removeEventListener('languageChanged', handleLanguageChange);
        };
    }, [i18n.language]);

    return (
        <>
            <Helmet>
                {/* ... Helmet content ... */}
                <title>{t('About Us - Our Mission and Approach')}</title>
                <meta name="description" content={t("We adhere to a belief - 'Give children a happy childhood.' Integrate happiness into learning.")} />
                <meta name="keywords" content={t(`Children's talent development, Creative learning Hong Kong, Enroll in AI courses for children, Join STUDYMIND EDU program`)} />

                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta property="og:title" content={t('STUDYMIND EDU - About Us - Our Mission and Approach')} />
                <meta property="og:description" content={t("We adhere to a belief - 'Give children a happy childhood.' Integrate happiness into learning.")} />
                <meta property="og:image" content="https://www.studymind.edu.hk/studymind_girl_1024x768.jpg" />
                <link rel="apple-touch-icon" href="https://www.studymind.edu.hk/studymind_girl_1024x768.jpg" />
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
            </Helmet>

            <footer className="footer">
                <div className="footer-container">
                    <div className="footer-content">
                        <div className="footer-links">
                            <Link to="/privacy-policy">{t('Privacy Policy')}</Link>
                            <Link to="/TermsOfUse">{t('Terms of Use')}</Link>
                        </div>
                        <div className="footer-copyright">
                            {t('Copyright {{year}} ©STUDYMIND EDU All Rights Reserved.', { year: new Date().getFullYear() })}
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;