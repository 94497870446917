// Header.js
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';
import languages from '../i18n/languages.json';
import { checkAndUpdateLanguage } from '../i18n/i18n';
import { Helmet } from 'react-helmet';

const Header = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const newLanguage = checkAndUpdateLanguage(i18n.language);
    if (newLanguage !== i18n.language) {
      i18n.changeLanguage(newLanguage);
    }
  }, [i18n]);

  const changeLanguage = (lng) => {
    const newLanguage = checkAndUpdateLanguage(lng);
    i18n.changeLanguage(newLanguage).then(() => {
      console.log('Language changed to:', newLanguage);
      window.dispatchEvent(new Event('languageChanged'));
    });
  };

  const handleNavClick = (event, path, sectionId) => {
    event.preventDefault();
    if (sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      } else {
        navigate(path);
        setTimeout(() => {
          const section = document.getElementById(sectionId);
          if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
          }
        }, 0);
      }
    } else {
      navigate(path);
    }
  };

  return (

    <>
      <Helmet>
        {/* ... Helmet content ... */}
        <title>{t('About Us - Our Mission and Approach')}</title>
        <meta name="description" content={t("We adhere to a belief - 'Give children a happy childhood.' Integrate happiness into learning.")} />
        <meta name="keywords" content={t(`Children's talent development, Creative learning Hong Kong, Enroll in AI courses for children, Join STUDYMIND EDU program`)} />

        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta property="og:title" content={t('STUDYMIND EDU - About Us - Our Mission and Approach')} />
        <meta property="og:description" content={t("We adhere to a belief - 'Give children a happy childhood.' Integrate happiness into learning.")} />
        <meta property="og:image" content="https://www.studymind.edu.hk/studymind_girl_1024x768.jpg" />
        <link rel="apple-touch-icon" href="https://www.studymind.edu.hk/studymind_girl_1024x768.jpg" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
      </Helmet>


      <Navbar bg="light" expand="lg" fixed="top" className="full-width-header">
        <Navbar.Brand as={Link} to="/">

          {/* STUDYMIND EDU */}

          <h1 class="navbar-brand">
            <span>S</span><span>T</span><span>U</span><span>D</span><span>Y</span><span>M</span><span>I</span><span>N</span><span>D</span><span>&nbsp;</span><span>E</span><span>D</span><span>U</span>
          </h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link onClick={(event) => handleNavClick(event, '/')}>{t('Home')}</Nav.Link>
            {/* <Nav.Link onClick={(event) => handleNavClick(event, '/AI4Good')}>{t('A.I. Transformation')}</Nav.Link> */}
            <Nav.Link onClick={(event) => handleNavClick(event, '/aboutus')}>{t('About Us')}</Nav.Link>
          <NavDropdown 
            title={
              <span>
                <i className="fas fa-globe">&nbsp; </i> {t('Language')}
              </span>
            } 
            id="language-nav-dropdown"
          >
            {Object.entries(languages).map(([code, name]) => (
              <NavDropdown.Item key={code} onClick={() => changeLanguage(code)}>
                {name}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    </>
  );

};

export default Header;