//Aboutus.jsx

import React, { useEffect } from 'react';
import { Container, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import './About_us.css';
import Helmet from 'react-helmet';
import { checkAndUpdateLanguage } from '../i18n/i18n';

const Aboutus = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const language = checkAndUpdateLanguage(i18n.language);
    console.log('About Us component rendered. Current language:', language);

    const handleLanguageChange = () => {
      console.log('Language change event detected in About Us');
      checkAndUpdateLanguage(i18n.language);
    };

    window.addEventListener('languageChanged', handleLanguageChange);

    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
    };
  }, [i18n.language]);

  return (
    <>
      <Helmet>
        {/* ... Helmet content ... */}
        <title>{t('About Us - Our Mission and Approach')}</title>
        <meta name="description" content={t("We adhere to a belief - 'Give children a happy childhood.' Integrate happiness into learning.")} />        
        <meta name="keywords" content={t(`Children's talent development, Creative learning Hong Kong, Enroll in AI courses for children, Join STUDYMIND EDU program`)} />

        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta property="og:title" content={t('STUDYMIND EDU - About Us - Our Mission and Approach')} />
        <meta property="og:description" content={t("We adhere to a belief - 'Give children a happy childhood.' Integrate happiness into learning.")} />
        <meta property="og:image" content="https://www.studymind.edu.hk/studymind_girl_1024x768.jpg" />
        <link rel="apple-touch-icon" href="https://www.studymind.edu.hk/studymind_girl_1024x768.jpg" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
      </Helmet>

      <Container>
        <div className="separator" />

        <h1 className="text-left">{t('About Us')}</h1>

        <div className="separator" />

        <Card className="fixed-width-card">
          <Card.Body>
            <Card.Title className="custom-card-title">{t('Our mission')}</Card.Title>
            <Card.Text className="custom-card-text">
            {t("We adhere to a belief - 'Give children a happy childhood, Unlocking Children's Talent and Creativity.")} 
            </Card.Text>
            <div className="custom-card-divider"></div>

            <Card.Title className="custom-card-title">{t('Address')}</Card.Title>
            <Card.Text className="custom-card-text">
              {t('No 102-103, 1/F, Hing Tung Shopping Centre, Eastern (Sai Wan Ho Station Exit B)')}
            </Card.Text>
            <div className="custom-card-divider"></div>

            <Card.Title className="custom-card-title">{t('Telephone')}</Card.Title>
            <Card.Text className="custom-card-text">
              {t('2535 7777')}
            </Card.Text>
            <div className="custom-card-divider"></div>

            <Card.Title className="custom-card-title">{t('Email')}</Card.Title>
            <Card.Text className="custom-card-text">
              <a
                style={{ textDecoration: 'none' }}
                href="mailto:info@studymind.edu.hk"
              >
                info@studymind.edu.hk
              </a>
            </Card.Text>


          </Card.Body>
        </Card>

        <div className="separator" />
      </Container>
    </>
  );
};

export default Aboutus;