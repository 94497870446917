import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './TermsOfUse.css';
import Helmet from 'react-helmet';
import { checkAndUpdateLanguage } from '../i18n/i18n';

const TermsOfUse = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const language = checkAndUpdateLanguage(i18n.language);
    console.log('Terms of Use component rendered. Current language:', language);

    const handleLanguageChange = () => {
      console.log('Language change event detected in Terms of Use');
      checkAndUpdateLanguage(i18n.language);
    };

    window.addEventListener('languageChanged', handleLanguageChange);

    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
    };
  }, [i18n.language]);

  return (
    <>
      <Helmet>
        <title>{t('Terms of Use - studymind.edu.hk')}</title>
        <meta name="description" content={t('Terms and conditions for using the studymind.edu.hk service.')} />
        <meta name="keywords" content={t('terms of use, conditions, user agreement, AI service')} />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta property="og:title" content={t('STUDYMIND EDU - Terms of Use')} />
        <meta property="og:description" content={t('Terms and conditions for using the studymind.edu.hk service.')} />
        <meta property="og:image" content="https://www.studymind.edu.hk/studymind_girl_1024x768.jpg" />
        <link rel="apple-touch-icon" href="https://www.studymind.edu.hk/studymind_girl_1024x768.jpg" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
      </Helmet>

      <Container className="TermsOfUse">
        <br /><br /><br /><br /><br />

        <h1>{t('Terms of Use')}</h1>
        <p>{t('Please read these terms and conditions carefully before using Our Service.')}</p>
        <br /><br />

        <section aria-labelledby="acknowledgement-heading">
          <h2 id="acknowledgement-heading">{t('Acknowledgement')}</h2>
          <p>{t('By accessing or using the studymind.edu.hk Service, you agree to be bound by these Terms and Conditions. If you disagree with any part of these terms, then you may not access the Service.')}</p>
        </section>
        <br />

        <section aria-labelledby="user-accounts-heading">
          <h2 id="user-accounts-heading">{t('User Accounts')}</h2>
          <p>{t('When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.')}</p>
        </section>
        <br />

        <section aria-labelledby="links-to-other-web-sites-heading">
          <h2 id="links-to-other-web-sites-heading">{t('Links to Other Web Sites')}</h2>
          <p>{t('Our Service may contain links to third-party web sites or services that are not owned or controlled by studymind.edu.hk. studymind.edu.hk has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services.')}</p>
        </section>
        <br />

        <section aria-labelledby="termination-heading">
          <h2 id="termination-heading">{t('Termination')}</h2>
          <p>{t('We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.')}</p>
        </section>
        <br />

        <section aria-labelledby="governing-law-heading">
          <h2 id="governing-law-heading">{t('Governing Law')}</h2>
          <p>{t('These Terms shall be governed and construed in accordance with the laws of Hong Kong, without regard to its conflict of law provisions.')}</p>
        </section>
        <br />

        <section aria-labelledby="changes-heading">
          <h2 id="changes-heading">{t('Changes')}</h2>
          <p>{t('We reserve the right to modify or replace these Terms at any time. We will provide at least 30 days\' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.')}</p>
        </section>
        <br />

        <section aria-labelledby="ai-content-heading">
          <h2 id="ai-content-heading">{t('AI-Generated Content')}</h2>
          <p>{t('Our website may include content generated by Artificial Intelligence (AI) tools. This includes, but is not limited to, text, images, and other multimedia content that is created with the assistance of or solely by AI technologies. While we strive to ensure the accuracy and relevance of such content, studymind.edu.hk does not guarantee that AI-generated information is error-free or entirely accurate.')}</p>
          <p>{t('Users should exercise their own judgment and discretion when using AI-generated content, especially when such content is used for decision-making or informational purposes. studymind.edu.hk is not responsible for any actions taken based on AI-generated content provided on the website.')}</p>
          <p>{t('AI-generated content may sometimes reflect the biases inherent in the data used to train the AI tools. As such, studymind.edu.hk makes no representation regarding the neutrality or objectivity of AI-generated content.')}</p>
          <p>{t('Any AI-generated content is provided for informational purposes only, and should not be considered as a substitute for professional advice where applicable. Users are encouraged to consult with a qualified professional before relying on any AI-generated content.')}</p>
          <p>{t('If you have concerns or doubts about the nature of any AI-generated content on our website, please contact us for clarification.')}</p>
        </section>
        <br />

        <section aria-labelledby="text-image-photo-credits-heading">
          <h2 id="text-image-photo-credits-heading">{t('Text, Image, and Photo Credits')}</h2>
          <p>{t('All text, images, and photos on our website are credited to their respective owners. If any owner discovers an issue with the use of their content on our website,')}</p>
          <p>{t('please email us directly at')} <a href="mailto:info@studymind.edu.hk" rel="noopener noreferrer">info@studymind.edu.hk</a>. {t('We will promptly address any concerns and take necessary actions to resolve the matter.')}</p>
        </section>
        <br /><br />

        <section aria-labelledby="contact-heading">
          <h2 id="contact-heading">{t('Contact Us')}</h2>
          <p>{t('If you have any questions about these Terms, please contact us:')}</p>
          <address>
            <a href="mailto:info@studymind.edu.hk" rel="noopener noreferrer">info@studymind.edu.hk</a>
          </address>
        </section>
        <br />
      </Container>
    </>
  );
};

export default TermsOfUse;